import React from "react"
import { Link } from "gatsby"
import GatsbyBackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "../main.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

/** @jsx jsx */
import { css, jsx } from "@emotion/core"

// images
import logo from "../img/logo.png"

import eauLogo from "../img/logo-eau-new.png"
import fortressLogo from "../img/logo-fortress-new.png"
import jgprolockLogo from "../img/logo-jgprolock-new.png"
import hempLogo from "../img/logo-hemp-new.png"

import nikeLogo from "../img/case-nike-logo.png"
import loewsLogo from "../img/case-loews-logo.png"
import aarpLogo from "../img/case-aarp-logo.png"
import hpLogo from "../img/case-hp-logo.png"
import curacaoWhiteLogo from "../img/curacao-logo-white.svg"
import bfbLogo from "../img/bfb-logo-white.svg"

import jgLogo from "../img/logo-jgprolock-new.png"
import yankeesLogo from "../img/yankees.png"
import curacaoLogo from "../img/logo-curacao-new.png"
import northwellLogo from "../img/logo-northwell-new.png"
import bfbLogoGrey from "../img/bfb-logo-grey.png"
import driveshackLogo from "../img/logo-driveshack.png"
import lufthansaLogo from "../img/lufthansa.png"
import cardtronicsLogo from "../img/logo-cardtronics-new.png"
import phaLogo from "../img/logo-pha.png"
import quiksilverLogo from "../img/logo-quiksilver-new.png"
import nygcLogo from "../img/logo-nygc.png"

import facebookLogo from "../img/facebook.png"
import linkedinLogo from "../img/linkedin.png"

import caseNike from "../img/case-nike.jpg"
import caseLoews from "../img/case-loews-v2.jpg"
import caseAarp from "../img/case-aarp.jpg"
import caseHp from "../img/case-hp.jpg"
import caseOverlay from "../img/case-overlay.png"

import nikeCollage from "../img/nike-collage.jpg"
import woodCollage from "../img/services-wood.jpg"

import blogVignette from "../img/blog-vignette.png"
import peopleBaby from "../img/people-baby.gif"
import peopleCoffee from "../img/people-coffee.gif"
import peopleMic from "../img/people-mic.gif"
import peoplePerson from "../img/people-person.gif"

import findUsVideoPoster from "../vid/makeout_2.jpg"

// video
import videoPoster from "../vid/catch.jpg"
import catchVideoMp4 from "../vid/Catch.mp4"
import catchVideoWebm from "../vid/Catch.webm"
import catchVideoOgv from "../vid/Catch.ogv"

import Slider from "react-slick"

const IndexPage = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <SEO title="Home" />
      <div className="all">
        <div id="home">
          <div className="main shift">
            <div className="center two">
              <div className="things">
                <div id="logo">
                  <img src={logo} alt="Catch" />
                </div>
                <p>
                  A Full Service, Fully Integrated Creative
                  <br /> Marketing, Branding, Advertising
                  <br />
                  Consulting, Innovation, Digital
                  <br />
                  <strong>Growth</strong> Agency.
                </p>
              </div>
            </div>
            <div className="video">
              <video preload="true" autoPlay muted loop poster={videoPoster}>
                <source src={catchVideoMp4} type="video/mp4" />
                <source src={catchVideoWebm} type="video/webm" />
              </video>
            </div>
          </div>
          <div className="intro">
            <div className="center three">
              <p>
                <strong>
                  Catch is built to reach, impact, and motivate audiences.
                </strong>{" "}
                It's a cause we're endlessly devoted to whether our clients like
                it or not.
              </p>
            </div>
          </div>
        </div>
        {/*//#home*/}
        {/* <div className="movie-holder" style={{ textAlign: "center" }}>
          <iframe
            src="https://player.vimeo.com/video/391577734"
            width={640}
            height={360}
            style={{ margin: "auto" }}
            frameBorder={0}
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          />
        </div> */}
        <div id="collage-curacao" className="hide">
          <div className="main shift">
            <div className="center three">
              <h1>
                <img src={curacaoWhiteLogo} />
                <span>
                  Our newest work for Curaçao is just one good example.
                </span>
                <span>
                  <Link to="/curacao" className="cta">
                    View Case Study ›
                  </Link>
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/*//#collage-one*/}
        <div id="collage-nike" className="hide">
          <div className="main shift">
            <div className="center three">
              <h1>
                <img src={nikeLogo} />
                <span>
                  And our success from Nike continues to stand the test of time.
                </span>
                <span>
                  <Link to="/nike" className="cta">
                    View Case Study ›
                  </Link>
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/*//#collage-one*/}
        <div id="collage-bfb" className="hide">
          <div className="main shift">
            <div className="center three">
              <h1>
                <img src={bfbLogo} />
                <span>
                  We're proud to grow the impact of regional brands too.
                </span>
                <span>
                  <Link to="/blue-foundry-bank" className="cta">
                    View Case Study ›
                  </Link>
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/*//#collage-one*/}
        <div id="collage-one-wood" className="hide">
          <div
            className="main"
            // css={css`background: url(${woodCollage}) no-repeat center top;`}
          >
            <div className="center three">
              <h1>
                <span>We are joined at the hip to accomplish one mission:</span>
                To help our clients grow and thrive, fast.
                <span>
                  <a href="/Catch-Fact-Sheet.pdf" target="_blank" className="cta">
                    Read Fact Sheet ›
                  </a>
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/*//#collage-one-wood */}
        <div id="pillars" className="hide">
          <div className="rewrap">
            <Slider {...slickSettings}>
              <div id="pillar-one" className="pillar">
                <div className="main">
                  <div className="center three">
                    <h1>
                      We're busy imagining lots of great stuff.
                      <span>
                        Conjuring and producing a seriously wide range of
                        commercial content, from ads that your parents might
                        see, and ad-like objects in places they won't.
                      </span>
                      <span>
                      <Link to="/blue-foundry-bank" className="cta">
                        View Case Study ›
                      </Link>
                    </span>
                    </h1>
                  </div>
                </div>
              </div>
              <div id="pillar-two" className="pillar">
                <div className="main">
                  <div className="center three">
                    <h1>
                      Using innovation to find meaning in our universe.
                      <span>
                        Creating game-changing ways to uncover new truths about
                        consumers and culture, while leveraging technology.
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
              <div id="pillar-three" className="pillar">
                <div className="main">
                  <div className="center three">
                    <h1>
                      Allowing big data to write some cool stories.
                      <span>
                        Analyzing data and relentlessly tweaking media formulas
                        and creative to optimize and improve the mood of our
                        clients.
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          {/*//.rewrap*/}
        </div>
        {/*//#pillars*/}
        <div id="work" className="hide this">
          <div className="full clearfix">
            <div
              className="four float case"
              style={{ backgroundImage: `url(${caseNike})` }}
            >
              <h3>Nike</h3>
              <span className="helper" />
              <Link to="/nike">
                <span>View this case study</span>
              </Link>
              <div className="shade" />
              <div className="shadow" />
              <div className="image-container">
                <img className="work-logo" src={nikeLogo} alt="Nike" />
              </div>
            </div>
            <div
              className="four float case"
              style={{ backgroundImage: `url(${caseLoews})` }}
            >
              <h3>Loews</h3>
              <span className="helper" />
              <Link className="loews loews-link" to="/loews">
                <span>View this case study</span>
              </Link>
              <div className="shade" />
              <div className="shadow" />
              <div className="image-container">
                <img className="work-logo" src={loewsLogo} alt="Loews" />
              </div>
            </div>
            <div
              className="four float case"
              style={{ backgroundImage: `url(${caseAarp})` }}
            >
              <h3>AARP</h3>
              <span className="helper" />
              <Link to="/aarp">
                <span>View this case study</span>
              </Link>
              <div className="shade" />
              <div className="shadow" />
              <div className="image-container">
                <img className="work-logo" src={aarpLogo} alt="AARP" />
              </div>
            </div>
            <div
              className="four float case"
              style={{ backgroundImage: `url(${caseHp})` }}
            >
              <h3>HP</h3>
              <span className="helper" />
              <Link to="/hp">
                <span>View this case study</span>
              </Link>
              <div className="shade" />
              <div className="shadow" />
              <div className="image-container">
                <img className="hp-logo work-logo" src={hpLogo} alt="HP" />
              </div>
            </div>
          </div>
          <div className="intro clearfix logo-wrapper">
            <div className="four float logo">
              <span className="helper" />
              <img src={curacaoLogo} alt="Curacao" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={driveshackLogo} alt="Drive Shack" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={bfbLogoGrey} alt="blue foundry bank logo" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={fortressLogo} alt="fortress" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={hempLogo} alt="Let There Be Hemp" />
            </div>

            <div className="four float logo">
              <span className="helper" />
              <img src={yankeesLogo} alt="yankees" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={cardtronicsLogo} alt="Cardtronics" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={phaLogo} alt="PHA" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={northwellLogo} alt="Northwell" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={jgLogo} alt="jgProlock" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={eauLogo} alt="eau logo" />
            </div>
            <div className="four float logo">
              <span className="helper" />
              <img src={lufthansaLogo} alt="lufthansa" />
            </div>
          </div>
        </div>
        {/*//#work*/}
        {/* <div id="blog-stuff" >
                <div className="main">
                    <div className="more">
                        <div className="post">
                            <h3>THE DRUM</h3>
                            <p>"...the right agency partner to develop and implement a marketing strategy in line with our company's innovations."<br />
                                <a style={{ fontSize: '16px' }} href="https://www.thedrum.com/news/2018/01/12/global-bike-share-platform-ofo-locks-up-catch-new-york-agency-record" target="_blank">Read More ›</a>
                            </p>
                        </div>
                        <div className="post">
                            <h3>THE DRUM</h3>
                            <p>"If it doesn't help the athlete, get rid of it" How Nike is reinventing performance eyewear.<br />
                                <a style={{ fontSize: '16px' }} href="https://www.thedrum.com/news/2016/05/11/if-it-doesn-t-help-athlete-get-rid-it-how-nike-reinventing-performance-eyewear" target="_blank">Read More ›</a>
                            </p>
                        </div>
                        <div className="post">
                            <h3>SUPER TOP SECRET PROJECT UNVEILED</h3>
                            <p>Vans releases Built [Rowley] Strong<br />
                                <a style={{ fontSize: '16px' }} href="https://www.youtube.com/watch?v=cznunknWXuA" target="_blank">Watch Video ›</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
        <div id="partners" className="hide">
          <div className="intro clearfix">
            <div className="bio">
              <div className="close">
                <a href="#">
                  <img src="img/close.png" alt="Close Bio" />
                </a>
              </div>
              <div className="bosses">
                <div className="doug">
                  <h3>Doug Spitzer</h3>
                  <span>Chief Creative Officer</span>
                  <p>
                    A co-founder of Catch and copywriter by trade, Doug
                    previously carried a title that made his parents proud as a
                    Senior Vice President, Creative Director with the Nitro
                    Group where he was responsible for the agency's Foot Locker
                    brand accounts, and its work with leading vendors Adidas,
                    Nike, and Puma. He also provided support to the agency's
                    Mars client on the Twix brand, on new business as Nitro's
                    Head of Retail Committee. Prior to that he was the Senior
                    Creative Director at AKA Advertising, the previous home of
                    the Foot Locker business. He joined AKA in 2003 after
                    spending four years as a creative and strategic consultant
                    for clients including Regal Cinemedia, SFX Sports Marketing
                    Group, Sony, Time Warner, and the four major American sports
                    leagues. Earlier in his career he worked for the National
                    Football League, departing as the Senior Manager of
                    Advertising and New Business, the youngest employee in the
                    company at that level. He began his journey as an errand boy
                    at Arnold in Boston and at Wells Rich Greene in Los Angeles.
                    Doug's work has been recognized by NBC, the New York Times,
                    USA Today and the Wall Street Journal, as well as with
                    several advertising industry honors his friends don't care
                    about. A graduate of Tufts University, he lives in Manhattan
                    with his two lovely TVs.
                  </p>
                </div>
                <div className="arie">
                  <h3>Arie Kovant</h3>
                  <span>Managing Partner</span>
                  <p>
                    After serving in the Air Force and a short stint at a large
                    agency (that shall remain nameless) in the early 1980's,
                    Arie felt the need to carve his own way and co-founded
                    Alperin &amp; Kovant, a boutique design shop. Over the next
                    decade he grew the shop into a full-service agency helping a
                    wide variety of retail and luxury clients, including
                    overseeing the launch of Innovation Luggage and Daniel K.
                    Jewelry. An entrepreneur at heart, Arie started over again
                    in 1999 as the founder and President of AKA Advertising. At
                    the diverse marketing agency dedicated to the branding and
                    promotion of some of the world's best known athletic brands,
                    he and the AKA team helped shape the growth of Foot Locker
                    into a global footware leader. Arie guided the company until
                    its eventual acquisition by Nitro in 2007. As a director on
                    the Executive Board of CAPS (Child Abuse Prevention
                    Services) Arie has been tirelessly raising money for the
                    prevention of child abuse for the past 12 years. At Catch,
                    Arie is focused on operations and client development, and
                    can be expected to treat clients as he always has... like
                    family.
                  </p>
                </div>
              </div>
            </div>
            {/*//.bio*/}
            <div className="partner float one doug">
              <div className="name">
                <h3>Doug Spitzer</h3>
                <strong>Chief Creative Officer</strong>
                <div className="button">
                  <a href="#doug">Doug's Bio</a>
                </div>
              </div>
            </div>
            <div className="partner float one arie">
              <div className="name">
                <h3>Arie Kovant</h3>
                <strong>Managing Partner</strong>
                <div className="button">
                  <a href="#arie">Arie's Bio</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*//#partners*/}
        <div id="collage-two" className="hide">
          <div
            className="main"
            css={css`
              background: url(../img/collage-two.jpg) no-repeat center top;
            `}
          >
            <div className="center three">
              <h1>
                <span>Best said, we're a hungry and aggressive agency</span>with
                a big heart and a light touch
                <span>and we hope you get to know us a lot better</span>
              </h1>
            </div>
          </div>
        </div>
        {/*//#collage-two*/}
        <div id="people" className="hide">
          <div className="intro clearfix">
            <div className="four float stat">
              <img src={peopleBaby} alt="Babies" />
              <strong>28</strong>
              <span>
                Median
                <br />
                Age
              </span>
            </div>
            <div className="four float stat">
              <img src={peopleCoffee} alt="Coffee" />
              <strong>95</strong>
              <span>
                Cups of Coffee
                <br />
                Per Day
              </span>
            </div>
            <div className="four float stat">
              <img src={peopleMic} alt="Mic" />
              <strong>7</strong>
              <span>
                Aspiring
                <br />
                Comedians
              </span>
            </div>
            <div className="four float stat">
              <img src={peoplePerson} alt="Person" />
              <strong>1</strong>
              <span>
                Person who is actually
                <br />
                funny. Maybe 2.
              </span>
            </div>
          </div>
        </div>
        {/*//#people*/}
        <div id="end" className="hide">
          <div className="main shift">
            <div className="clearfix">
              <div className="three float">
                <h3>Work for us.</h3>
                <h4>
                  Innovators. Artists. Passionate People.
                  <br />
                  <em>We're hiring.</em>
                </h4>
                <p>
                  We're looking for a commitment to our core values of
                  Innovation, Creativity, and Thought. Please send your resume
                  and cover letter to:{" "}
                  <a href="mailto:contact@catch-nyc.com">
                    contact@catch-nyc.com
                  </a>{" "}
                  or{" "}
                  <a href="mailto:creativejobs@catch-nyc.com">
                    creativejobs@catch-nyc.com
                  </a>
                </p>
              </div>
              <div className="three float">
                <h3>Talk to us.</h3>
                <h4>
                  MAVERICKS. THOUGHT LEADERS. REALLY BIG DEALS.
                  <br />
                  <em>WE'RE LISTENING.</em>
                </h4>
                <p>
                  If you're looking for an agency to partner with to launch your
                  next big idea then please send us the scope of your project
                  to:{" "}
                  <a href="mailto:newbusiness@catch-nyc.com">
                    newbusiness@catch-nyc.com
                  </a>
                </p>
              </div>
              <div className="three float">
                <h3>Follow us.</h3>
                <h4>
                  BLOGGERS. FANS. PRIVATE DETECTIVES.
                  <br />
                  <em>WE'RE OUT THERE.</em>
                </h4>
                <p>
                  If you're seeking out interesting stories and the latest,
                  greatest on all things Catch then please follow us at:
                </p>
                <ul id="social">
                  <li>
                    <a href="https://www.facebook.com/CatchNewYork">
                      <img src={facebookLogo} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/925399?trk=tyah&trkInfo=tas%3Acatch%20n%2Cidx%3A2-3-4">
                      <img src={linkedinLogo} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*//#end*/}
        <div id="find-us">
          <div className="main">
            <div className="center half">
              <h1>
                Find Us
                <br />
                <span class="address">
                  <a
                    href="https://goo.gl/maps/CT56inuYDHW9PGDr5"
                    target="_blank"
                  >
                    15 East 32nd Street 4th Floor, NYC, 10016
                  </a>
                </span>
                <span class="address">
                  <a href="tel:212-715-8700">212.715.8700</a>
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/*//#find-us*/}
      </div>
      {/* #all */}
    </Layout>
  )
}

export default IndexPage
